import React, { useState } from 'react';
import './Navbar.css';
import SearchIcon from '../../static/icons8-search.svg';
import InstagramIcon from '../../static/icons8-instagram.svg';
import logo from '../../static/logo-with-text.svg';
import { useNavigate } from "react-router-dom";

const Navbar = ({hasScrolled}) => {
  const navigate = useNavigate();

  function handleNavClick(route) {
    navigate(route);
    window.scrollTo({top:0, behavior: 'smooth' })
  }

  function handleInstagramClick() {
    window.open('https://www.instagram.com/myinnermonologue.blog/');
  }
  
    return (
      <div className={`nav-bar ${hasScrolled && 'scrolling'}`}>
        <div className="logo">
          <div className="title-text-container" onClick={() => handleNavClick("/")}>
            <img className="title-text" src={logo}/>
          </div>
        </div>
        <div className="tabs">
          <div className="tab-list">
            <p className="tab-text" onClick={() => handleNavClick("/about")} >About</p>
            <p className="tab-text" onClick={() => handleNavClick("/posts")}>Posts</p>
            <div className="tab-text">
              <img className="social-media-link" src={InstagramIcon} onClick={() => handleInstagramClick()}/>
            </div>
          </div>
          {/* <div className="search-field">
            <div className="input-group">
              <div className="input">
                <p className="text-5">Placeholder</p>
                <button className="button">
                  <img src={SearchIcon} />
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
  
export default Navbar;
  
