import React, { forwardRef, useState, useRef, useEffect } from "react";
import { FullWidthText } from '../Text';
import { BlogPostChunkEnum } from '../../constants';

import './PostBody.css';


const PostBody  = forwardRef(({contentChunks}, innerRef) => {
  let firstRefLink;

  const onRefLinkClick = (linkUrl) => {
    window.open(linkUrl);
  }

  const getChunkVariation = (chunk, idx, classNames) => {
    console.log({chunk})
    if (chunk && chunk.chunkNumber && chunk.chunkNumber === 1) {
      classNames.push('first-paragraph')
    }

    switch(chunk.type) {
      case BlogPostChunkEnum.TEXT:
        return <FullWidthText 
          key={`fullWidthText=${idx}`} 
          classNames={classNames} 
          text={chunk ? chunk.text : ''}
        />;

      case BlogPostChunkEnum.LINK:
        if (!firstRefLink) {
          firstRefLink = chunk.id
        } 

        return (
          <div>
            {firstRefLink && firstRefLink === chunk.id && <p className="list-title list-container ref-title">Links:</p>}
            <li 
              className={`${classNames.join(' ')} list-container ref-item link`} 
              key={idx} 
              onClick={() => onRefLinkClick(chunk.linkUrl)}
            >
              {chunk && chunk.text}
            </li> 
          </div>)

      default:
        return;    
      }
  }


  return (
    <div ref={innerRef} className="post-container">
      {contentChunks && contentChunks.length && contentChunks.length > 0 ? contentChunks.map(function(chunk, idx){
        const classNames = [];
        // adjust styling 
        if (idx === 0) {
          classNames.push('first')
        } else if (idx === contentChunks.length -1) {
          classNames.push('last')
        }

        return (
          <div>
            {getChunkVariation(chunk, idx, classNames)}
          </div>
        );
      }) : null}
    </div>
  )
})

export default PostBody;
  