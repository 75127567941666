import React, { useEffect, useState } from "react";
import PostContainer from "./PostContainer";
import { listBlogPosts } from '../../services/blog-post-service';

const MainPostContainer  = () => {
  const [mainPostId, setMainPostId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let blogPosts = await listBlogPosts();
      if (blogPosts && blogPosts.length && blogPosts.length > 0) {
          const mainPost = blogPosts[0];
          setMainPostId(mainPost.id);
      }
    };
    fetchData();
  }, [])

  return (
    <div>
      <PostContainer postId={mainPostId}/>
    </div>
  )
}

export default MainPostContainer;
  