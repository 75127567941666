import React, { useEffect, useState, useRef } from "react";
import { PostBody } from "../PostBody";
import { PostComments } from "../PostComments";
import { LargeThumbnailText } from '../LargeThumbnailText';

import './Post.css';

const Post  = ({title, blurb, contentChunks, blogPostId, comments}) => {
  const postRef = useRef();

  return (
    <div className="post">
      <div className="thumbnail-container">
        <LargeThumbnailText title={title} blurb={blurb} />
        <PostBody innerRef={postRef} contentChunks={contentChunks} blogPostId={blogPostId} />
        <PostComments comments={comments} blogPostId={blogPostId}/>
      </div>

    </div>
  )
  }

export default Post;
  