import React, { useState, useEffect } from "react";
import { CommentInput } from './CommentInput';
import { CommentList } from './CommentList';

import './PostComments.css';

const PostComments  = ({blogPostId, comments}) => {
  const [commentsList, setCommentsList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (comments && comments.length && comments.length > 0) {
        setCommentsList(comments)
      }
    };
    fetchData();
  }, [comments])


  const onCreateNewComment = (newComment) => {
    let updatedCommentsList = [newComment].concat(commentsList);
    setCommentsList(updatedCommentsList);
  }

  return (
    <div className="post-comments-container">
      <CommentInput blogPostId={blogPostId} createNewCommentCallback={onCreateNewComment}/>
      <CommentList comments={commentsList}/>
    </div>
  )
}

export default PostComments;

  