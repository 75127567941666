import React, { useEffect, useState } from "react";
import { Post } from "../../component/Post";
import { getBlogPost } from '../../services/blog-post-service';
import { useLocation } from 'react-router-dom';

const PostContainer  = ({postId}) => {
  const [postContent, setPostContent] = useState([]);
  const [postComments, setPostComments] = useState([]);
  const [postTitle, setPostTitle] = useState('');
  const [postBlurb, setPostBlurb] = useState('');
  const [blogPostId, setBlogPostId] = useState('');
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      const pathPostId = location && location.state && location.state.postId;
      if (postId) {
        setBlogPostId(postId);
      } else if (pathPostId) {
        setBlogPostId(pathPostId);
      }
    };
    fetchData();
  }, [postId])

  useEffect(() => {
    async function fetchData() {
      if (blogPostId) {
        let post = await getBlogPost(blogPostId);

        setPostTitle(post.title);
        setPostComments(post.comments);
        setPostContent(post.chunks);
        setPostBlurb(post.blurb);
      }
    };
    fetchData();
  }, [blogPostId])

  return (
    <div>
      <Post key={blogPostId} title={postTitle} blurb={postBlurb} contentChunks={postContent} blogPostId={blogPostId} comments={postComments}/>  
    </div>
  )
}

export default PostContainer;
  