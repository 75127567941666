import { API, graphqlOperation } from 'aws-amplify';
import moment from "moment";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

// helper functions
const orderArrayItems = (arr, param) => {
    let returnArray = [];
    for (var i=0; i < arr.length; i++) {
        var filteredArray = arr.filter(arrItem => {
            return parseInt(arrItem[param]) === i + 1
          })
        if (filteredArray.length > 0) {
            returnArray.push(filteredArray[0]);
        }
    }
    return returnArray;
}

const formatDate = (item, dateParam) => {
    const initialDate = item[dateParam];
    return {
        ...item, 
        [dateParam]: moment(initialDate).format('MMMM Do YYYY')
    }
}

const formatDates = (datesArray, dateParam) => {
    let returnArray = [];

    const sortedArray = datesArray.sort((a, b) => moment(b[dateParam]).diff(moment(a[dateParam])))
    returnArray = sortedArray.map(item => {
        return formatDate(item, dateParam);
    });
    return returnArray;
}

const createGetBlogPostInput = (blogPostId) => {
    return {
        id: blogPostId
    }
}

const orderBlogPostChunks = (blogPostChunks) => {
    let blogPostChunksSorted = [];
    if (blogPostChunks && blogPostChunks.length && blogPostChunks.length > 0 ) {
        blogPostChunksSorted = blogPostChunks.sort((a,b) => a.chunkNumber - b.chunkNumber);

    }
    return blogPostChunksSorted;
}

// CRUD operations
export const getBlogPost = async (blogPostId) => {
    const getBlogPostContentInput = createGetBlogPostInput(blogPostId);
    const getBlogPostResponse = await API.graphql(graphqlOperation(queries.getBlogPost, getBlogPostContentInput));

    if (!getBlogPostResponse || !getBlogPostResponse.data || !getBlogPostResponse.data.getBlogPost) {
        return;
    }

    // create return object
    const blogPost = {
        id: getBlogPostResponse.data.getBlogPost.id,
        title: getBlogPostResponse.data.getBlogPost.title,
        blurb: getBlogPostResponse.data.getBlogPost.blurb,
        comments: getBlogPostResponse.data.getBlogPost.BlogPostComments && getBlogPostResponse.data.getBlogPost.BlogPostComments.items,
        chunks: getBlogPostResponse.data.getBlogPost.BlogPostChunks && getBlogPostResponse.data.getBlogPost.BlogPostChunks.items && orderBlogPostChunks(getBlogPostResponse.data.getBlogPost.BlogPostChunks.items),
    };

    return blogPost;
};

export const listBlogPosts = async () => {
    let blogPosts = [];
    const listBlogPostsResponse = await API.graphql(graphqlOperation(queries.listBlogPosts));
    if (!listBlogPostsResponse || !listBlogPostsResponse.data || !listBlogPostsResponse.data.listBlogPosts) {
        return;
    }

    if (listBlogPostsResponse.data.listBlogPosts.items 
        && listBlogPostsResponse.data.listBlogPosts.items.length 
        && listBlogPostsResponse.data.listBlogPosts.items.length > 0) {
            const filteredBlogPosts = listBlogPostsResponse.data.listBlogPosts.items.filter(item => item._deleted !== true /*&& item.isActive === 'true'*/);
            const formattedBlogPosts = formatDates(filteredBlogPosts, 'createdAt');
            blogPosts = formattedBlogPosts;
    }
    return blogPosts;
}

export const createBlogPostComment = async (postValues, blogPostId) => {
    if (!postValues || !postValues.name || !postValues.email || !postValues.comment) {
        return;
    }

    const createBlogPostCommentInput = {
        input: {
            name: postValues.name,
            email: postValues.email,
            comment: postValues.comment,
            blogpostID: blogPostId
        }
        
    };

    const createBlogPostCommentResponse = await API.graphql(graphqlOperation(mutations.createBlogPostComment, createBlogPostCommentInput));
    return createBlogPostCommentResponse;
}
