/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBlogPostComment = /* GraphQL */ `
  mutation CreateBlogPostComment(
    $input: CreateBlogPostCommentInput!
    $condition: ModelBlogPostCommentConditionInput
  ) {
    createBlogPostComment(input: $input, condition: $condition) {
      id
      name
      email
      comment
      blogpostID
      createdAt
      updatedAt
    }
  }
`;
export const updateBlogPostComment = /* GraphQL */ `
  mutation UpdateBlogPostComment(
    $input: UpdateBlogPostCommentInput!
    $condition: ModelBlogPostCommentConditionInput
  ) {
    updateBlogPostComment(input: $input, condition: $condition) {
      id
      name
      email
      comment
      blogpostID
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlogPostComment = /* GraphQL */ `
  mutation DeleteBlogPostComment(
    $input: DeleteBlogPostCommentInput!
    $condition: ModelBlogPostCommentConditionInput
  ) {
    deleteBlogPostComment(input: $input, condition: $condition) {
      id
      name
      email
      comment
      blogpostID
      createdAt
      updatedAt
    }
  }
`;
export const createBlogPostChunk = /* GraphQL */ `
  mutation CreateBlogPostChunk(
    $input: CreateBlogPostChunkInput!
    $condition: ModelBlogPostChunkConditionInput
  ) {
    createBlogPostChunk(input: $input, condition: $condition) {
      id
      chunkNumber
      text
      blogpostID
      createdAt
      updatedAt
    }
  }
`;
export const updateBlogPostChunk = /* GraphQL */ `
  mutation UpdateBlogPostChunk(
    $input: UpdateBlogPostChunkInput!
    $condition: ModelBlogPostChunkConditionInput
  ) {
    updateBlogPostChunk(input: $input, condition: $condition) {
      id
      chunkNumber
      text
      blogpostID
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlogPostChunk = /* GraphQL */ `
  mutation DeleteBlogPostChunk(
    $input: DeleteBlogPostChunkInput!
    $condition: ModelBlogPostChunkConditionInput
  ) {
    deleteBlogPostChunk(input: $input, condition: $condition) {
      id
      chunkNumber
      text
      blogpostID
      createdAt
      updatedAt
    }
  }
`;
export const createBlogPost = /* GraphQL */ `
  mutation CreateBlogPost(
    $input: CreateBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    createBlogPost(input: $input, condition: $condition) {
      id
      title
      isActive
      blurb
      BlogPostChunks {
        items {
          id
          chunkNumber
          text
          blogpostID
          createdAt
          updatedAt
        }
        nextToken
      }
      BlogPostComments {
        items {
          id
          name
          email
          comment
          blogpostID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBlogPost = /* GraphQL */ `
  mutation UpdateBlogPost(
    $input: UpdateBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    updateBlogPost(input: $input, condition: $condition) {
      id
      title
      isActive
      blurb
      BlogPostChunks {
        items {
          id
          chunkNumber
          text
          blogpostID
          createdAt
          updatedAt
        }
        nextToken
      }
      BlogPostComments {
        items {
          id
          name
          email
          comment
          blogpostID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlogPost = /* GraphQL */ `
  mutation DeleteBlogPost(
    $input: DeleteBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    deleteBlogPost(input: $input, condition: $condition) {
      id
      title
      isActive
      blurb
      BlogPostChunks {
        items {
          id
          chunkNumber
          text
          blogpostID
          createdAt
          updatedAt
        }
        nextToken
      }
      BlogPostComments {
        items {
          id
          name
          email
          comment
          blogpostID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
