import React from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './FullWidth.css';

const FullWidth = ({text, classNames=[]}) => {
    return (
      <div className={`posttext--fullwidth ${classNames.join(' ')}`}>
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="full-width-text">{text}</ReactMarkdown>
      </div>
    )
  }

export default FullWidth;

  