import React, { forwardRef, useState, useRef, useEffect } from "react";
import './Comment.css';

const Comment  = ({comment}) => {

  return (
    <div className="comment">
        <div className="container">
          <div className="content">
            <p className="text-1">{comment.name}</p>
            <p className="text-2">{comment.comment}</p>
          </div>
        </div>
        <div className="divider light"/>
      </div>
  )
}

export default Comment;

  
  