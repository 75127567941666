import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { Navbar, } from './component';
import { AboutContainer, MainPostContainer, PostListContainer, PostContainer } from './container';

import './App.css';

function App() {
  const [hasScrolled, userHasScrolled] = useState(false);

  window.onscroll = (e) => {  
    if (window.pageYOffset !== 0) {
      userHasScrolled(true)
    } else {
      userHasScrolled(false)
    }
  }


  return (
    <div className="App">
      <Router>
        <Navbar hasScrolled={hasScrolled}/>
        <div className='main-content-container'>
          <Routes>
            <Route exact path="/" element={<MainPostContainer/>} />
            <Route path="/about" element={<AboutContainer/>} />
            <Route exact path="/posts" element={<PostListContainer/>} />
            <Route path="/posts/:postId"  element={<PostContainer />} />
          </Routes>
        </div>
      </Router>
      
    </div>
  );
}

export default App;