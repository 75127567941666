import React, { useEffect, useState } from "react";
import {Post} from "../../component/Post";
import { getBlogPost } from '../../services/blog-post-service';

import './AboutContainer.css';

const AboutContainer  = () => {
  const [aboutMeContent, setAboutMeContent] = useState([]);
  const [aboutMeTitle, setAboutMeTitle] = useState('');
  const [aboutMePhoto, setAboutMePhoto] = useState('');
  const [blogPostId, setBlogPostId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setBlogPostId('15249772-8606-11ed-a1eb-0242ac120002');
    };
    fetchData();
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (blogPostId && blogPostId !== '') {
        let aboutMePost = await getBlogPost(blogPostId);
        if (aboutMePost) {
          setAboutMeTitle(aboutMePost.title);
          setAboutMeContent(aboutMePost.chunks);
        }  
      } 
    };
    fetchData();
  }, [blogPostId])

  return (
    <Post title={aboutMeTitle} contentChunks={aboutMeContent} titlePhoto={aboutMePhoto} blogPostId={blogPostId}/>
  )
}

export default AboutContainer;
  