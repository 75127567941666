import React from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createBlogPostComment } from '../../../services/blog-post-service';
import './CommentInput.css';

const CommentInput  = ({blogPostId, createNewCommentCallback}) => {

  const initialValues = {
    name: '',
    email: '',
    comment: ''
  };

  const saveComment = async (values, resetForm) => {
    const newComment = await createBlogPostComment(values, blogPostId);

    if (newComment && newComment.data && newComment.data.createBlogPostComment) {
      createNewCommentCallback(newComment.data.createBlogPostComment)
    }
    resetForm(initialValues);
  }


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(),
      email: Yup.string()
        .email("Invalid email address")
        .required("Req0-uired"),
      comment: Yup.string()
        .required("Required")
    }),
    onSubmit: (values, { resetForm }) => {
      saveComment(values, resetForm);
    }
  });


  // disable submit button on initial form load, if the form is not valid, or if the form is submitting
  const disabledSubmitButton = (formik.values.name === initialValues.name 
    && formik.values.email === initialValues.email 
    && formik.values.comment === initialValues.comment) 
    || formik.isSubmitting 
    || !formik.isValid;
  
  return (
      <div className="comment-input-container">
        <div className="comment-input">
          <div className="comment-disclaimer">
            <p className="bold">I want to hear from you. Leave a comment below.</p>
            <p className="medium-font">Your email address will not be published.</p>
          </div>
          <div className="divider"/>
          <form className="comment-form medium-font" onSubmit={formik.handleSubmit}>
            <div className="text-field">
              <label htmlFor="name" className="label-text">Name:</label>
              <div className="input-container">
                <input
                  className="input-half-width"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="John Doe"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </div>
            </div>
            <div className="text-field">
              <label htmlFor="email" className="label-text">Email:</label>
              <div className="input-container">
                <input
                  className="input-half-width"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="johndoe@email.com"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
            </div>
            <div className="text-field">
              <label htmlFor="comment" className="label-text">Comment:</label>
              <div className="input-container">
                <textarea
                  className="input-full-width"
                  id="comment"
                  name="comment"
                  placeholder="This is a comment..."
                  onChange={formik.handleChange}
                  value={formik.values.comment}
                />
              </div>
            </div>
            <div className="divider extra-margin"/>
              <div className="save-button-container">
                <button disabled={disabledSubmitButton} type="submit" className={`save-button ${disabledSubmitButton ? 'disabled' : ''}`} >
                  <p className="save-button-text">Save</p>
                </button>
              </div>
          </form>

        </div>
      </div>
  )
}

export default CommentInput;

  