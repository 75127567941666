import React, { useEffect, useState } from "react";
import { PostListItem } from "../../component";
import { listBlogPosts } from '../../services/blog-post-service';

import './PostListContainer.css';

const PostListContainer  = () => {
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const blogPosts = await listBlogPosts();
      setPostList(blogPosts);
    };
    fetchData();
  }, [])

  return (
    <div className="post-list-container">
      {(postList && postList.length && postList.length > 0) ? postList.map(function(post, idx){
        return (
            <PostListItem 
              key={idx}
              blogPostId={post && post.id} 
              image={post && post.titlePhoto} 
              title={post && post.title} 
              date={post && post.createdAt}
              blurb={post && post.blurb}
            />
        );
      }) : null}
    </div>
  )
}

export default PostListContainer;
  