import React, { useEffect } from "react";
import './LargeThumbnailText.css';

const LargeThumbnailText  = ({title, blurb}) => {

    return (
        <div className="large-thumbnail-text">
          <div className="large-thumbnail-text-flex-container">
            <div className="large-thumbnail-text-title-container">
              <p className="large-thumbnail-text-title">{title}</p>
            </div>
            <p className="large-thumbnail-text-subtitle">{blurb}</p>
          </div>
        </div>
    )
  }

export default LargeThumbnailText;
  