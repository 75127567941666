/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBlogPostComment = /* GraphQL */ `
  query GetBlogPostComment($id: ID!) {
    getBlogPostComment(id: $id) {
      id
      name
      email
      comment
      blogpostID
      createdAt
      updatedAt
    }
  }
`;
export const listBlogPostComments = /* GraphQL */ `
  query ListBlogPostComments(
    $filter: ModelBlogPostCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPostComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        comment
        blogpostID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBlogPostChunk = /* GraphQL */ `
  query GetBlogPostChunk($id: ID!) {
    getBlogPostChunk(id: $id) {
      id
      chunkNumber
      text
      blogpostID
      createdAt
      updatedAt
    }
  }
`;
export const listBlogPostChunks = /* GraphQL */ `
  query ListBlogPostChunks(
    $filter: ModelBlogPostChunkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPostChunks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chunkNumber
        text
        blogpostID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBlogPost = /* GraphQL */ `
  query GetBlogPost($id: ID!) {
    getBlogPost(id: $id) {
      id
      title
      isActive
      blurb
      BlogPostChunks {
        items {
          id
          chunkNumber
          text
          type
          linkUrl
          blogpostID
          createdAt
          updatedAt
        }
        nextToken
      }
      BlogPostComments {
        items {
          id
          name
          email
          comment
          blogpostID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBlogPosts = /* GraphQL */ `
  query ListBlogPosts(
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        isActive
        blurb
        BlogPostChunks {
          nextToken
        }
        BlogPostComments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const blogPostCommentsByBlogpostID = /* GraphQL */ `
  query BlogPostCommentsByBlogpostID(
    $blogpostID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogPostCommentsByBlogpostID(
      blogpostID: $blogpostID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        comment
        blogpostID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listChunksByBlogPost = /* GraphQL */ `
  query ListChunksByBlogPost(
    $blogpostID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostChunkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChunksByBlogPost(
      blogpostID: $blogpostID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chunkNumber
        text
        blogpostID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
