import React, { useEffect, useState } from "react";
import { convertDateToFormattedString } from '../../util';
import { useNavigate } from "react-router-dom";

import './PostListItem.css';

const PostListItem = ({blogPostId, title, date, blurb}) => {
    const navigate = useNavigate();

    const onReadMoreClick = (blogPostId) => {
        navigate(`/posts/${blogPostId}`, {
          state: {
            postId: blogPostId,
          }
        })
        window.scrollTo({top:0, behavior: 'smooth' })
    }
    
    return (
      <div className="post-list-item-container" onClick={() => onReadMoreClick(blogPostId)}>
        <div className="post-blurb">
          <div className="post-details-container">
            <p className="post-date">{convertDateToFormattedString(date)}</p>
            <div className="post-title-container">
              <p className="post-title">{title}</p>
            </div>
          </div>
        </div>
        <div className="post-thumbnail-container">
          <p className="post-thumbnail-blurb">{blurb}</p>
        </div>
      </div>
    )
  }

export default PostListItem;
  