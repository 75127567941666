import React, { forwardRef, useState, useRef, useEffect } from "react";
import './CommentList.css';
import { Comment } from '../Comment';

const CommentList  = ({comments}) => {

  return (
    <div className="comment-container">
      <div className="comment-disclaimer">
        <p className="bold">See what others are saying:</p>
      </div>
      <div className="divider upper-margin"/>
      {(comments && comments.length && comments.length > 0) ? comments.map(function(comment, idx) {
          return (<Comment key={idx} comment={comment}/>)
      }) : null}
    </div>
  )
}

export default CommentList;

  
  